import React from "react";
import { injectIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import aboutStyle from "./styling/about.module.css";
import PageHeader from "../components/header/pageHeader";

class Imprint extends React.Component {
  render() {
    const {
      location: { pathname: path },
      intl,
    } = this.props;

    return (
      <Layout
        display="true"
        path={path}
        title={intl.formatMessage({ id: "imprint.title" })}
      >
        <PageHeader header="header.imprint" />
        <div className={aboutStyle.imprintContainer}>
          <p>
            <strong>narando GmbH</strong>
            <br />
            Rahdenstrasse 35
            <br />
            49326 Melle
          </p>
          <p>
            Mobil: +49 (0) 151 2415 6303
            <br />
            E-Mail: <a href="mailto:contact@narando.de">contact@narando.de</a>
          </p>
          <p>
            Vertretungsberechtigter Geschäftsführer: Christian Brandhorst
            <br />
            Registergericht: Amtsgericht Osnabrück Registernummer: HRB 209688
            <br />
            Steuernummer: DE301236237{" "}
          </p>
          <h3>Haftungshinweis:</h3>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten ausschließlich deren Betreiber verantwortlich. Die
            verwendeten Bilder und Texte auf dieser Webseite sind - sofern nicht
            anders angegeben - Eigentum der narando GmbH und dürfen ohne
            persönliche Absprache nicht von Dritten genutzt werden.
          </p>
        </div>
        <div className="fillContainer" />
      </Layout>
    );
  }
}

export default injectIntl(Imprint);
