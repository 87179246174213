import React, { Component } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import "./header.css";

class PageHeader extends Component {
  render() {
    return (
      <div className="pageHeaderWrapper">
        <h1 className="pageHeader">
          <FormattedMessage id={this.props.header} />
        </h1>
      </div>
    );
  }
}

export default PageHeader;
